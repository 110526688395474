import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Header, Layout, LinkList } from '../components/common'
import { ROUTES } from '../constants/routes'

const Utils: React.FC = () => {
  const data = useStaticQuery(graphql`
    fragment UtilFragment on ContentfulDocument {
      caption
      link
      name
    }

    query Utils {
      documents: allContentfulDocument(filter: { external: { eq: false } }) {
        nodes {
          ...UtilFragment
        }
      }
      links: allContentfulDocument(filter: { external: { eq: true } }) {
        nodes {
          ...UtilFragment
        }
      }
    }
  `)

  return (
    <Layout navigation={{ location: ROUTES.UTILS }}>
      <Header title="Utili" />
      <LinkList
        title="Documenti"
        items={data.documents.nodes}
        icon="cloud-download-alt"
      />
      <LinkList title="Link" items={data.links.nodes} icon="link" />
    </Layout>
  )
}

export default Utils
